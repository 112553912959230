import React from "react";
import img from "../Images/timyan.png";

function TimyanNicePage() {
  return (
    <div className="timyan-container">
      <div className="timyan-nice-page-card">
        <div className="right-panel-response">
          <img src={img} alt="404" />
        </div>
        <div className="left-panel">
          {/* <i
            class="bx bx-left-arrow-alt"
            onClick={() => {
              window.open("/timyan", "_self");
            }}
          ></i> */}
          <div className="buttons">
            <h1>Спасибо за оценку! Оставьте пожалуйста свой отзыв на одной из платформ</h1>
            <button id="yandex">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Yandex_icon.svg/1200px-Yandex_icon.svg.png"
                alt="404"
              />{" "}
              Yandex <div className="design" id="design-yandex"></div>
            </button>
            <button id="google">
              <img
                src="https://static.vecteezy.com/system/resources/previews/022/484/503/non_2x/google-lens-icon-logo-symbol-free-png.png"
                alt="404"
              />{" "}
              Google <div className="design" id="design-google"></div>
            </button>
            <button id="gis">
              <img
                src="https://play-lh.googleusercontent.com/XVD5b0yJl830a8_gga0Pwqn4D8bEpa-k00piLYVgPegVPYqgcom4Wdq-irjQlM2hsw"
                alt="404"
              />{" "}
              2ГИС <div className="design" id="design-gis"></div>
            </button>
          </div>
        </div>
        <div className="right-panel">
          <img src={img} alt="404" />
        </div>
      </div>
    </div>
  );
}

export default TimyanNicePage;
