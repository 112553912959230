import React from "react";
import img from "../Images/timyan.png";
import { Rate } from "antd";

function Timyan() {
  return (
    <div className="timyan-container">
      <div className="timyan-body-card">
        <div className="body-card-header">
          <img src={img} alt="404" />
          <h1>
            Вы посетили магазин цветов Тимьян в Красноярске 
          </h1>
        </div>
        <div className="body-card-rate">
          <h1>Вам понравилось?</h1>
          <Rate
            id="rate-card"
            defaultValue={0}
            onChange={(value) => {
              if (value === 5) {
                window.open("timyan/nice-rate", "_self");
              } else {
                window.open("timyan/bad-rate", "_self");
              }
            }}
          />
        </div>
        <div className="body-card-note">
          {/* <p>
            Примечание. Каждый оставленный вами комментарий очень важен для нас!
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Timyan;
