import React, { useEffect, useState } from "react";
import logo from "../Images/map.png";
import settings1 from "../Images/settings-1.png";
import settings2 from "../Images/settings-2.png";
import settings3 from "../Images/settings-3.png";
import settings4 from "../Images/settings-4.png";

function Admin() {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    async function fetchApiData() {
      try {
        const response = await fetch(
          "https://b863-92-38-53-32.ngrok-free.app/links/get_all"
        );

        // Server status kodini tekshirish
        if (!response.ok) {
          throw new Error(
            `Server xatosi: ${response.status} - ${response.statusText}`
          );
        }

        // Ma'lumotlarni JSON ga aylantirishdan oldin formatni tekshirish
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Javob JSON formatida emas!");
        }

        const data = await response.json();
        console.log("Olingan ma'lumotlar:", data);
      } catch (error) {
        console.error("API'dan ma'lumot olishda xatolik:", error);
      }
    }

    fetchApiData();
  }, []);

  const subjects = ["Google", "Yandex", "2Gis", "Waze", "Apple maps"];
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleCheckboxChange = (subject) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
    } else if (selectedSubjects.length < 3) {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
  };

  return (
    <div className="admin-panel">
      <div className="container">
        <div className="header-div">
          <img src={logo} alt="404" />
          <h1>Админ-панель</h1>
        </div>
        <div className="body-settings">
          <div className="background-design">
            <div className="left-panel">
              <img src={settings1} alt="404" />
            </div>
            <div className="right-panel">
              <h1>1.Изменить цвет фона сайта</h1>
              <p>Введите "hex" код цвета</p>
              <div className="buttons">
                <input
                  type="text"
                  name="color"
                  placeholder="#FFFF"
                  id="color"
                />
                <button>Изменять</button>
              </div>
              <div className="note-for">
                <h3>
                  Напоминание! Здесь можно ввести только "hex" (цветовой код).
                </h3>
              </div>
            </div>
          </div>
          <div className="logo-reset">
            <div className="left-panel">
              <h1>2.Изменить логотип</h1>
              <p>Выберите изображение</p>
              <input type="file" />
              <br />
              <button>Изменять</button>
              <div className="note-for">
                <h3>
                  Напоминание! Размер отправляемой вами картинки должен быть
                  500x500 пикселей!
                </h3>
              </div>
            </div>
            <div className="right-panel">
              <img src={settings2} alt="404" />
            </div>
          </div>
          <div className="location-reset">
            <div className="left-panel">
              <img src={settings3} alt="404" />
            </div>
            <div className="right-panel">
              <h1>3.Изменить адрес</h1>
              <p>Введите текст</p>
              <input type="text" />
              <button>Изменять</button>
              <div className="note-for">
                <h3>Напоминание! Ваш текст не должен превышать 75 символов.</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="nicepage-settings">
          <div className="buttons-edit">
            <div className="left-panel">
              <h1>4.Добавить или удалить кнопки</h1>
              <p>Введите текст</p>
              {subjects.map((subject) => (
                <label
                  key={subject}
                  style={{ display: "block", marginBottom: "5px" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedSubjects.includes(subject)}
                    onChange={() => handleCheckboxChange(subject)}
                    disabled={
                      !selectedSubjects.includes(subject) &&
                      selectedSubjects.length >= 3
                    }
                  />
                  {subject}
                </label>
              ))}
              {/* <p>
                Tanlangan predmetlar:{" "}
                {selectedSubjects.length > 0
                  ? selectedSubjects.join(", ")
                  : "Hech biri tanlanmagan"}
              </p> */}

              <button>Изменять</button>
              <div className="note-for">
                <h3>Напоминание! Ваш текст не должен превышать 75 символов.</h3>
              </div>
            </div>
            <div className="right-panel">
              <img src={settings4} alt="404" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
