import { Alert } from "antd";
import React, { useRef } from "react";
import img from "../Images/timyan.png";
import emailjs from "@emailjs/browser";

function TimyanBadPage() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_c8n18lq", "template_w0q7da8", form.current, {
        publicKey: "IXupG565aI-d4vNbT",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          const alert = document.getElementById("alert");
          const design = document.getElementById("design");

          alert.style.display = "block";
          design.style.display = "block";

          setTimeout(() => {
            alert.style.display = "none";
            design.style.display = "none";
            window.location.reload();
          }, 5000);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="timyan-container">
      <div className="timyan-bad-page-card">
        <div
          id="design"
          onClick={() => {
            const alert = document.getElementById("alert");
            const design = document.getElementById("design");
            alert.style.display = "none";
            design.style.display = "none";
            window.location.reload();
          }}
        ></div>
        <Alert
          id="alert"
          message="Ваше сообщение успешно отправлено !"
          description="Приносим извинения за неудобства и постараемся решить проблему как можно скорее !"
          type="success"
          showIcon
        />
        {/* <i
          className="bx bx-left-arrow-alt"
          onClick={() => {
            window.open("/timyan", "_self");
          }}
        ></i> */}
        <div className="right-panel-response">
          <img src={img} alt="404" />
        </div>
        <div className="left-panel">
          <div className="container">
            <h1>
            Спасибо за вашу обратную связь. Расскажите, что вам не понравилось, чтобы мы смогли стать лучше ?
            </h1>
            <div className="inputs">
              <form ref={form} onSubmit={sendEmail}>
                <label htmlFor="problem">Что не понравилось </label>
                {/* <input
                  type="text"
                  id="problem"
                  name="text"
                  placeholder="Введите текст"
                /> */}
                <textarea name="text" id="problem" cols="30" rows="10"></textarea>
                <label htmlFor="email">Эл. почта</label>
                <input type="email" id="email" name="email" />
                <label htmlFor="tel">Телефон </label>
                <input
                  type="text"
                  id="tel"
                  name="phone_number"
                  placeholder="+7(xxx)xxx-xx-xx "
                />
                <label htmlFor="name">Имя </label>
                <input type="text" id="name" name="name" />
                <button>Отправить</button>
              </form>
              {/* <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form> */}
            </div>
          </div>
        </div>
        <div className="right-panel">
          <img src={img} alt="404" />
        </div>
      </div>
    </div>
  );
}

export default TimyanBadPage;
