import React from "react";
import { Rate } from "antd";
import NicePage from "./NicePage";
import { Link } from "react-router-dom";
import img from "../Images/map.png"

function MapBodyCard() {
  return (
    <div className="body-card">
      <div className="body-card-header">
        <img
          src={img}
          alt="404"
        />
        <h1>
        Добро пожаловать в наш магазин в Красноярске, проспект 60 лет Образования СССР, 19.
        </h1>
      </div>
      <div className="body-card-rate">
        <h1>Как вам наши услуги? Вы можете оценить нас</h1>
        <Rate
          id="rate-card"
          defaultValue={0}
          onChange={(value) => {
            if (value === 5) {
              window.open("/nice-rate", "_self");
            } else {
              window.open("/bad-rate", "_self");
            }
          }}
        />
      </div>
      <div className="body-card-note">
        <p>Примечание. Каждый оставленный вами комментарий очень важен для нас!</p>
      </div>
    </div>
  );
}

export default MapBodyCard;
