import React from "react";
import MapBodyCard from "./Components/MapBodyCard";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import NicePage from "./Components/NicePage";
import BadPage from "./Components/BadPage";
import Admin from "./Components/Admin";
import Timyan from "./Pages/Timyan";
import TimyanNicePage from "./Pages/TimyanNicePage";
import TimyanBadPage from "./Pages/TimyanBadPage";
import Status from "./Pages/Status";
import StatusNice from "./Pages/StatusNice";
import StatusBad from "./Pages/StatusBad";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MapBodyCard />} />
        <Route path="nice-rate" element={<NicePage />} />
        <Route path="bad-rate" element={<BadPage />} />
        <Route path="timyan" element={<Timyan />} />
        <Route path="timyan/nice-rate" element={<TimyanNicePage />} />
        <Route path="timyan/bad-rate" element={<TimyanBadPage />} />
        <Route path="status" element={<Status />}/>
        <Route path="status/nice-rate" element={<StatusNice />}/>
        <Route path="status/bad-rate" element={<StatusBad />}/>   
        <Route
          path="F9CpSb7k7GzMc4j6QAeiG3rev60iIsOv4cxWdiMzBmLZcYXILuGO4AsZz4Kc3HKI"
          element={<Admin />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
